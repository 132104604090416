// Core
import { useLayoutEffect, useEffect } from "react";

// Components
import { WritingInfo } from "../JobTypesInfo/WritingInfo";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useForms } from "../../../hooks/useForms";
import { useOrder } from "../../../hooks/useOrder";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { initDefaultRedactor, getActiveWritingSub, getLastSubByTalent } from "../../../helpers/helpers";
import { isEmpty } from "ramda";

export const WritingSubscription = ({ page, fields }) => {
    /* Hooks */
    const {
        isDetails,

        getFormatField,
        getBanner,
        getIndustryField,
        getWordCountSimpleField,
        getTopicWritingField,
        getBriefField,
        getWritersField,
        getTurnaroundField,
        getAdditionalDetailsLabel,
        getLocalizationField,
        getTagsField,
        getCopyStyleVoiceField,
        getAdditionalDetailsFields,

        onRedactorSynced,
        onRedactorBlur
    } = fields;
    const { keys: { accountId }} = useAuth();
    const { updateFormState } = useForms();
    const { duplicatedProject, editedProject } = useOrder();
    const { isAuth } = useUi();
    const { balance, company: { subscriptions }} = useUser();
    const { words_balance, type } = balance;

    useLayoutEffect(() => {
        initDefaultRedactor('#writing-brief', 'brief', null, null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#writing-brief', 'destroy');
        };
    }, [balance]);
    useEffect(() => {
        let activeSub = getActiveWritingSub(accountId, subscriptions);
        let lastSub = getLastSubByTalent(accountId, subscriptions, 'writers');
        let words = 100, level = 'pro';

        if ( !isEmpty(lastSub) ) {
            ({ level, words } = lastSub);
        }
        if ( !isEmpty(activeSub) ) {
            ({ level, words } = activeSub);
        }
        if ( page === 'editing' ) {
            ({ level, word_count: words } = editedProject);
        }
        if ( page === 'new' && !isEmpty(duplicatedProject) ) {
            ({ level, word_count: words } = duplicatedProject);
        }

        updateFormState(`writingSubscriptionForm`, { word_count: words, level });
    }, [subscriptions]);

    if ( isAuth && isEmpty(balance) ) return null;

    if ( type !== 'writers' || (words_balance < 1 || !words_balance) ) {
        return <WritingInfo/>;
    }

    return <>
        { getFormatField() }
        { getBanner('seobrief')}
        { getIndustryField() }
        { getWordCountSimpleField() }
        { getTopicWritingField() }
        { getBriefField(true) }
        { getWritersField() }
        { getTurnaroundField() }
        { getAdditionalDetailsLabel() }
        { getLocalizationField() }
        { isDetails && <>
            { getTagsField() }
            { getCopyStyleVoiceField(null) }
            { getAdditionalDetailsFields() }
        </> }
    </>;
};